import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Layout from "../components/layout"
import Seo from "../components/seo"
import Booking from '../components/dashboard/class'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { getMethod } from '../helper/api';
import { navigate , Link} from "gatsby";
import { notify } from 'react-notify-toast';
import { CardActionArea } from '@material-ui/core';
import { propTypes } from 'react-bootstrap/esm/Image';

const Payments = ({ location, userDetails }) => {


    //const [transactions, setTransactions] = useState([]);
    //const [wallet, setWallet] = useState({});


    // useEffect(() => {
    //     // getMyReview()

    //     async function getWallet() {
    //         let walletData = await getMethod('/wallets', '', { user_id: userDetails.id });
    //         setWallet(walletData.data[0]);
    //     }

    //     async function getTransactions() {
    //         let transactions = await getMethod('/wallet-transactions?_sort=id:DESC', '', { user_id: userDetails.id });

    //         for(let i=0; i<transactions.data.length;i++){
    //             //console.log(transactions["data"][i]);
    //                 if(transactions["data"][i].description.indexOf('cartId:')!==-1){
    //                     let cartDetails = await getMethod('/carts',"",{id:transactions["data"][i].description.replace('cartId:','').trim()});
    //                     Object.assign(transactions["data"][i],{'subject':cartDetails["data"][0].subject})
    //                     Object.assign(transactions["data"][i],{'teacher':cartDetails["data"][0].faculty})
    //                 }
    //         }

    //         setTransactions(transactions.data);
    //     }

    //     getTransactions();
    //     getWallet();
    // }, []);

    //console.log(location.state);''
    const [view, showView] = useState('earnings');

    if (!location.state) {
        location.state = {payments:[],earnings:{earnings:0, total_payouts:0, payouts:[]}}
    }

    return (
        <Layout title="Payments">
            <Seo title="Payments" />
            <div className="wallet-transition">
                <div className="container">
                    <div className="wallet-card card bg-primary text-white">
                        <div className="row align-items-center gx-3">
                            <div className="col ps-4" onClick={()=>showView('earnings')}>
                                <div>Earnings (£)</div>
                                <h3 class="hours text-white mb-0">{location.state?.earnings.earnings}</h3>
                            </div>
                       
                            <div className="col ps-4" onClick={()=>showView('payments')}>
                                <div>Payments(£)</div>
                                <h3 class="hours text-white mb-0">{location.state?.earnings.total_payouts? location.state?.earnings.total_payouts:0}</h3>
                            </div>

                            <div className="col ps-4">
                                <div>Balance (£)</div>
                                <h3 class="hours text-white mb-0">{location.state?.earnings.earnings - location.state?.earnings.total_payouts}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="nav-tab">
                        <div className={view==='earnings'?"tab-link active":"tab-link"} onClick={()=>showView('earnings')}><span style={{textDecoration:'none', color:'revert'}}>Earnings</span></div>
                        <div className={view==='payments'?"tab-link active":"tab-link"} onClick={()=>showView('payments')}><span style={{textDecoration:'none', color:'revert'}} >Payments</span></div>
                    </div>
                    {view==='earnings' && <>
                    {/* <h5 className="pt-2">Earnings</h5> */}
                    <div className="table-responsive transition-table-t">
                        <table className="table">
                            <thead className="table-light">
                                <tr className="table-heading">
                                    <th className="hrs  text-black">Date</th>
                                    <th className="hrs  text-black">Details</th>
                                    <th className="hrs  text-black">Price per hour</th>
                                    <th className="hrs  text-black">Hours</th>
                                    <th>Earning</th>
                                </tr>
                            </thead>
                            <tbody>
                            {location.state?.payments!==0 && location.state?.payments?.map((item) => {    
	    			return (
                                    
                                        <tr>
                                            <td>
                                                <div className="hrs text-black">{new Date(item.date).toDateString().slice(4, 10)}</div>
                                            </td>
                                            <td className="hrs  text-black" style={{width:'120px','wordWrap':'break-word', 'display':'block'}}>{item.student} / {item.subject} / {new Date(item.date).toLocaleDateString('en-GB')} / {item.id} / {parseFloat(item.payment)<0?<span style={{color:'red'}}> {item.dispute_approved==1?'Disputed':'Cancelled'}</span>:''}</td>
                                            {parseFloat(item.payment)>0 && <td className={parseFloat(item.payment)>0?"hrs text-center text-black":"hrs text-center text-red"}>{item.payment/((new Date(item.end_time).getTime()-new Date(item.start_time).getTime())/(60*60*1000))}</td>}
                                            {parseFloat(item.payment)>0 && <td className="hrs text-black">{(new Date(item.end_time).getTime()-new Date(item.start_time).getTime())/(60*60*1000)} hrs</td>}
                                            {parseFloat(item.payment)<0 && <td className="hrs text-black"></td>}
                                            {parseFloat(item.payment)<0 && <td className="hrs text-black"></td>}
                                            <td className={parseFloat(item.payment)>0?"hrs text-center text-black":"hrs text-center text-red"}>£ {item.payment}</td>
                                        </tr>
                                    
                                )
                            })}
	    		    {location.state?.payments==0 && <tr><td colspan="4" style={{textAlign:"center"}}>No bookings yet</td></tr>}
                    </tbody>
                        </table>
                    </div></>}

                    {view==='payments' && <>
                    {/* <h5 className="pt-2">Payments</h5> */}
                    <div className="table-responsive transition-table-t">
                        <table className="table">
                            <thead className="table-light">
                                <tr className="table-heading">
                                    <th className="hrs  text-black">Date</th>
                                    <th className="hrs  text-black">Paid</th>
                                </tr>
                            </thead>
                            <tbody>
                            {location.state?.payments!==0 && location.state?.earnings.payouts?.map((item) => {    
	    			return (
                                    
                                        <tr>
                                            <td>
                                                <div className="hrs text-black">{new Date(item.date).toDateString().slice(4, 10)}</div>
                                            </td>
                                            <td className="hrs  text-black">£ {item.amount_paid}</td>
                                        </tr>
                                    
                                )
                            })}
	    		    {location.state?.payments==0 && <tr><td colspan="4" style={{textAlign:"center"}}>No bookings yet</td></tr>}
                    </tbody>
                        </table>
                    </div></>}
                </div>
            </div>        
        </Layout>
    );
}

export default connect(state => ({ userDetails: state.loginReducer.authenticate.user }), null)(Payments);
